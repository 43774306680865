import { default as abuseReportsH64owYUtOWMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93RGRo0XhUNcMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as index2pJbX1OFq5Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/index.vue?macro=true";
import { default as moderationLpDbaP5oRZMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/moderation.vue?macro=true";
import { default as pendingBanss8qbnnuk2CMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/pendingBans.vue?macro=true";
import { default as profilesfpk3f9kP0eMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/profiles.vue?macro=true";
import { default as expensesdbwx87lhFlMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexrU8Jy3pZ93Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewZufE2MnC0nMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingOdj3qXsbRdMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingiUMGUvj7J8Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/translations/missing.vue?macro=true";
import { default as usersGLMMQmDEuLMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/users.vue?macro=true";
import { default as apix6657UcQdvMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/api.vue?macro=true";
import { default as _91slug_935et2l0FGJTMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/blog/[slug].vue?macro=true";
import { default as indexefPtVVcHQFMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93l6uF41GaBNMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93T5zhg7MXfoMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as admina8OEjmACGoMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/census/admin.vue?macro=true";
import { default as indexG9J4ItO5TsMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/census/index.vue?macro=true";
import { default as contact7q8QtnaTVdMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/contact.vue?macro=true";
import { default as design6GDnDJfkg9Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/design.vue?macro=true";
import { default as englishUMVnhtUOAhMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/english.vue?macro=true";
import { default as faqaQHmFfGqjsMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/faq.vue?macro=true";
import { default as inclusivesHzUxVHfINMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/inclusive.vue?macro=true";
import { default as indexm8wOS8OkqoMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/index.vue?macro=true";
import { default as licensemhsBZjE6AaMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/license.vue?macro=true";
import { default as academicGIRwXUdxRXMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/academic.vue?macro=true";
import { default as indexEveVVojjjKMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/index.vue?macro=true";
import { default as mediaF3pBriDaVjMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/media.vue?macro=true";
import { default as translinguisticsvll2VewugyMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/translinguistics.vue?macro=true";
import { default as zinegFJzSgiD0tMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/zine.vue?macro=true";
import { default as namesvnFQohSbNsMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/names.vue?macro=true";
import { default as indexGrNIMNqSzNMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/nouns/index.vue?macro=true";
import { default as templatesbGhiVoHxw9Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/nouns/templates.vue?macro=true";
import { default as peopleW1ZHJiTGIuMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/people.vue?macro=true";
import { default as privacy83AHh8BllmMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/privacy.vue?macro=true";
import { default as _91username_93CoeEvhRkeDMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93xYBvZCn8elMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/profile/card-[username].vue?macro=true";
import { default as editorliXIHRniJ4Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/profile/editor.vue?macro=true";
import { default as anyP0aeYFw6woMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/any.vue?macro=true";
import { default as avoidingJmV2w2yopPMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/avoiding.vue?macro=true";
import { default as indexpQzINagfHuMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/index.vue?macro=true";
import { default as mirror3TqaSsiJXYMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/mirror.vue?macro=true";
import { default as pronounnD5hfzsRejMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesfhj7MYJW75Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/sources.vue?macro=true";
import { default as teamyG2YtfKo7yMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/team.vue?macro=true";
import { default as terminologybkZLP3Uao5Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/terminology.vue?macro=true";
import { default as terms9kzA0nJS8tMeta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/terms.vue?macro=true";
import { default as user4xQZNX3tp1Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/user.vue?macro=true";
import { default as workshopsaf3TNflil6Meta } from "/home/admin/www/en.pronouns.page/release/20241011101349/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsH64owYUtOWMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBanss8qbnnuk2CMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apix6657UcQdvMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_935et2l0FGJTMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexefPtVVcHQFMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93l6uF41GaBNMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93T5zhg7MXfoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: admina8OEjmACGoMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexG9J4ItO5TsMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact7q8QtnaTVdMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishUMVnhtUOAhMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqaQHmFfGqjsMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivesHzUxVHfINMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicGIRwXUdxRXMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexEveVVojjjKMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaF3pBriDaVjMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsvll2VewugyMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinegFJzSgiD0tMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesvnFQohSbNsMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexGrNIMNqSzNMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesbGhiVoHxw9Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleW1ZHJiTGIuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy83AHh8BllmMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93CoeEvhRkeDMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93xYBvZCn8elMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorliXIHRniJ4Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyP0aeYFw6woMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingJmV2w2yopPMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexpQzINagfHuMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirror3TqaSsiJXYMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounnD5hfzsRejMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesfhj7MYJW75Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamyG2YtfKo7yMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologybkZLP3Uao5Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms9kzA0nJS8tMeta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: user4xQZNX3tp1Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsaf3TNflil6Meta || {},
    component: () => import("/home/admin/www/en.pronouns.page/release/20241011101349/pages/workshops.vue")
  }
]