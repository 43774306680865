import revive_payload_client_tp0SwHQq5v from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_a6hCUuIlVB from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8uOFQXSt2A from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_vOz1DmQMoO from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_q4RIifLR2H from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_DzeeF5XgIc from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_BcdGKLwfJS from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OVFu2pURjg from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.22.2_typescript@5.6.2_vue@3.5.7/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/admin/www/en.pronouns.page/release/20241011101349/.nuxt/components.plugin.mjs";
import prefetch_client_XXXZY8H9a2 from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/nuxt@3.13.2_@types+node@20.16.5_eslint@8.57.1_rollup@4.22.2_sass@1.32.12__ydqwum6exgwbk22mhj5oetqsgm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/admin/www/en.pronouns.page/release/20241011101349/.nuxt/pwa-icons-plugin.ts";
import pwa_client_2PJJotjkME from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_rollup@4.22.2_vite@5.4.7_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_ZYLtg96Wdj from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_rollup@4.22.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import auth_vT9JWWT9pN from "/home/admin/www/en.pronouns.page/release/20241011101349/plugins/auth.ts";
import browserDetect_3Y4e6ym8wL from "/home/admin/www/en.pronouns.page/release/20241011101349/plugins/browserDetect.ts";
import globals_qEnHRCYwqu from "/home/admin/www/en.pronouns.page/release/20241011101349/plugins/globals.ts";
import polyfill_client_aOiFD9Uk19 from "/home/admin/www/en.pronouns.page/release/20241011101349/plugins/polyfill.client.ts";
import sentry_client_shVUlIjFLk from "/home/admin/www/en.pronouns.page/release/20241011101349/plugins/sentry.client.ts";
import track_client_qrg1Mffzr3 from "/home/admin/www/en.pronouns.page/release/20241011101349/plugins/track.client.ts";
import plugin_auto_pageviews_client_jrYWKnC3th from "/home/admin/www/en.pronouns.page/release/20241011101349/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_rollup@4.22.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_tp0SwHQq5v,
  unhead_a6hCUuIlVB,
  router_8uOFQXSt2A,
  payload_client_vOz1DmQMoO,
  navigation_repaint_client_q4RIifLR2H,
  check_outdated_build_client_DzeeF5XgIc,
  chunk_reload_client_BcdGKLwfJS,
  plugin_vue3_OVFu2pURjg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_XXXZY8H9a2,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_2PJJotjkME,
  plugin_client_ZYLtg96Wdj,
  auth_vT9JWWT9pN,
  browserDetect_3Y4e6ym8wL,
  globals_qEnHRCYwqu,
  polyfill_client_aOiFD9Uk19,
  sentry_client_shVUlIjFLk,
  track_client_qrg1Mffzr3,
  plugin_auto_pageviews_client_jrYWKnC3th
]